import React, { useEffect, useState } from "react";

// theme colors
import { Colors } from "../../theme/colors";

// image
import AuthBackgroundImage from "../../assets/BackGroundImg.png";
import TIRONEM_LOGO from "../../assets/TIRONEM_LOGO.png";
import {
  Typography,
  TextField,
  Button,
  ButtonBase,
  Stack,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import CssTextField from "../../utils/CssTextField";
// import CryptoJS from "crypto-js";
// Redux
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as GoogleIcon } from "../../assets/svg/google.svg";

import { useNavigate, Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { FACEBOOK_APP_ID, GOOGLE_APP_ID } from "../../utils/config";
import useToaster from "../../utils/Toaster";
import { facebookLogin, googleLogin, loginUser } from "../../api/auth";
// import FacebookLoginRender from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookProvider, LoginButton, useLogin } from "react-facebook";
import LoadingAnimation from "../../components/LoadingAnimation";
import bg_top from "../../assets/bg_top.png";
import bg_bottom from "../../assets/bg_bottom.png";
import useInterviewContext from "../../Context/mainContext";
import { Invitation, getCampaignByCandidateId } from "../../api/questionAudio";

type Props = {};

const Index = (props: Props) => {
  const navigate = useNavigate();

  const maxWidth1366 = useMediaQuery("(max-width: 1366px)");
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [msg, sendNotification] = useToaster();
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  let interviewState: any = useInterviewContext();

  useEffect(() => {
    if (interviewState.onboardingCandidateData) {
      setEmail(
        interviewState.onboardingCandidateData.CampaignLine_CandidateEmail
      );
    }
  }, [interviewState]);

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    var code = e.keyCode || e.which;
    if (code === 13) {
      onSubmit();
    }
  };

  // function decryptStringWithKey(encryptedString: string, key: string) {
  //   const bytes = CryptoJS.AES.decrypt(encryptedString, key);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // }

  const onSubmit = async () => {
    setIsLoading1(true);
    let message: string;
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
        password
      )
    ) {
      message =
        "Password must contain at least one small and capital letter , special characters  and numbers.";
      sendNotification({ msg: message, variant: "error" });
      setIsLoading1(false);
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      message = "email format is not valid";
      sendNotification({ msg: message, variant: "error" });
      setIsLoading1(false);
    } else {
      const loginData = {
        email,
        password,
      };

      const response: any = await loginUser(loginData);

      if (response?.statusText === "OK" || response?.status === 200) {
        const token = response?.data.access_token;
        // const encryptionKey = "C-link";
        if (token) {
          let a: number = 0;
          // const candidateLink = localStorage.getItem('candidate_link') || '';
          const candidateLink = sessionStorage.getItem("candidate_link") || "";

          if (candidateLink === null || candidateLink === "") {
            localStorage.setItem("token", token);
            const campaignId = sessionStorage.getItem("campaignInfoCode") || "";
            const enterpriseId = sessionStorage.getItem("entInfoCode") || "";

            // const campaignId = decryptStringWithKey(keyInfo, encryptionKey);
            // console.log("Decrypted string:", campaignId);
            Invitation([
              {
                CampaignLine_CandidateEmail: email,
                fk_Campaign_ID: campaignId,
                fk_Enterprise_ID: Number(enterpriseId),
                fk_Job_ID: 0,
              },
            ]);
            const response = await getCampaignByCandidateId(campaignId, email);
            if (response) {
              if (response.status === 200) {
                sessionStorage.setItem(
                  "candidate_link",
                  response?.data?.CampaignLine_CandidateLink
                );
                localStorage.setItem("token", token);
                localStorage.setItem("step_active", a.toString());
                let message = "Login successful.";
                sendNotification({ msg: message, variant: "success" });
                const getToken = localStorage.getItem("token");
                setIsLoading1(false);
                if (getToken) {
                  let a: number = 0;

                  localStorage.setItem("step_active", a.toString());
                  navigate("/modal");
                }
              } else {
                navigate("/notstarted");
              }
            }
          } else {
            localStorage.setItem("token", token);
            localStorage.setItem("step_active", a.toString());
            let message = "Login successful.";
            sendNotification({ msg: message, variant: "success" });
            const getToken = localStorage.getItem("token");
            setIsLoading1(false);
            if (getToken) {
              let a: number = 0;

              localStorage.setItem("step_active", a.toString());
              navigate("/modal");
            }
          }
        }
      } else {
        let message = response?.message ?? "Login failed.";
        sendNotification({ msg: message, variant: "error" });
        setIsLoading1(false);
      }
    }
  };

  const { login, status, isLoading, error } = useLogin();

  const responseFacebook = async () => {
    const response: any = await facebookLogin();
  };

  const responseGoogle = async () => {
    const response: any = await googleLogin();
  };

  const handleLoginfacebook = async () => {
    try {
      const response = await login({
        scope: "email",
        // id: ""
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const getToken = localStorage.getItem("token");
    if (getToken) {
      navigate("/modal");
    }
  }, []);

  return (
    <div
      style={{
        position: "relative",
        background: "white",
        height: "100vh",
        // width: '100vw',
        width: "100%",
        // minHeight: '750px',
        overflow: "hidden",
      }}
    >
      <img
        src={AuthBackgroundImage}
        alt="AuthBackground_Image"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      />
      <div
        style={{
          position: "relative",
          zIndex: 10,
          // <Box
          //   sx={{
          //     background: 'rgba(255, 255, 255, 0.3)',
          //     position: 'relative',
          //     height: '90%',
          //     width: '92%',
          //     borderRadius: '20px',
          //     border: '3px solid #FFFFFF',
          //     backdropFilter: 'blur(40px)',
        }}
      >
        <div
          style={{
            marginTop: maxWidth1536 ? "50px" : "65px",
            // marginTop: maxWidth1366 ? '50px' : '65px',
            display: "flex",
            justifyContent: "center",
            // <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
            //   <img src={bg_top} />
            // </Box>
            // <Box sx={{ position: 'absolute', bottom: '0', left: '0' }}>
            //   <img src={bg_bottom} />
            // </Box>
            // <Box
            //   sx={{
            //     position: 'relative',
            //     zIndex: 10,
          }}
        >
          {/* header */}
          {/* <Box
            sx={{
              marginTop: maxWidth1536 ? '40px' : '50px',
              display: 'flex',
              justifyContent: 'center',
            }}
          > */}
          {/* <img
            src={TIRONEM_LOGO}
            alt="TIRONEM_logo"
            style={{
              width: "216px",
              height: "49px",
            }}
          /> */}
          {/* </Box> */}
        </div>

        <div
          style={{
            // marginTop: maxWidth1366 ? '80' : '168px',
            marginTop: maxWidth1536 ? "140px" : "168px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // marginTop: '11vh',
          }}
        >
          <Typography
            color={Colors.black}
            sx={{ fontSize: "26px", fontWeight: "600" }}
          >
            Welcome Back
          </Typography>
          <Typography
            color={Colors.Phlox}
            sx={{
              marginTop: "10px",
              fontWeight: 500,
              borderRadius: "10px",
              fontSize: "20px",
            }}
          >
            Log in to your account
          </Typography>
          <div
            style={{
              marginTop: "40px",
              width: "100%",
              maxWidth: "414px",
            }}
          >
            <CssTextField
              id="outlined-basic"
              variant="outlined"
              disabled={
                interviewState.onboardingCandidateData &&
                interviewState.onboardingCandidateData
                  .CampaignLine_CandidateEmail
                  ? true
                  : false
              }
              value={email}
              placeholder="Enter your email"
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              maxWidth: "414px",
            }}
          >
            <CssTextField
              id="outlined-basic"
              type="password"
              value={password}
              disabled={isLoading1}
              variant="outlined"
              placeholder="Enter password"
              onKeyPress={(e: any) => handleKeypress(e)}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              width: "100%",
              maxWidth: "414px",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{
                width: "100%",
                fontSize: 20,
                height: 50,
                background:
                  "linear-gradient(269.39deg, #6559D7 -3.77%, #AA19D5 78.96%)",
              }}
              onClick={() => onSubmit()}
            >
              {isLoading1 ? (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                    }}
                    // aria-controls={isOpenedJobType ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    // aria-expanded={isOpenedJobType ? 'true' : undefined}
                  >
                    <CircularProgress
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "white",
                      }}
                    />
                  </Box>
                </>
              ) : (
                "Sign In"
              )}
            </Button>
            {/* <CustomButton title="Sign In" onClick={() => onSubmit()} /> */}
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize="16px"
              variant="h6"
              color={Colors.spanish_gray}
              sx={{
                fontWeight: 500,
                marginRight: "3px",
              }}
            >
              New to here?
            </Typography>
            <Typography
              variant="h6"
              fontSize="16px"
              component={Link}
              to="/signup"
              color={Colors.vivid_mulberry}
              sx={{
                fontWeight: 600,
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Create an account
            </Typography>
          </div>
          {/*<div
            style={{
              padding: '30px 0',
              textAlign: 'center',
            }}
          >
            <Typography
              fontSize="16px"
              variant="h6"
              color={Colors.spanish_gray}
            >
              Or
            </Typography>
          </div>
          <Stack
            spacing={2}
            direction="row"
            sx={{ width: '100%', maxWidth: '414px' }}
          >
            <GoogleLogin
              clientId={GOOGLE_APP_ID} //CLIENTID NOT CREATED YET
              render={(renderProps) => (
                <Button
                  size="large"
                  variant={'contained'}
                  color={'primary'}
                sx={{
                    width: '100%',
                    height: '5%',
                    padding: '10px 0',
                    borderRadius: '10px',
                    background: '#ffffff',
                  color: '#464646',
                    fontSize: '16px',
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                  ':hover, :focus, :active': {
                       background: `#ffffff`,
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                        },
                  }}
                  startIcon={<GoogleIcon />}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                 >
                  Google
                  </Button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={(e) => console.log('FAIL', e)}
              cookiePolicy={'single_host_origin'}
            />

            <Button
              size="large"
              variant={'contained'}
              color={'primary'}
              sx={{
                width: '100%',
                height: '5%',
                padding: '10px 0',
                borderRadius: '10px',
                background: '#ffffff',
                color: '#464646',
                // border: '1px solid #E5E5E5',
                fontSize: '16px',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                ':hover, :focus, :active': {
                  background: `#ffffff`,
                  boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                },
              }}
              startIcon={<FacebookIcon />}
              onClick={handleLoginfacebook}
              disabled={isLoading}
            >
              Facebook
            </Button>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              width: '100%',
              maxWidth: '414px',
              color: '#989797',
              fontSize: '16px',
              marginTop: '30px',
              fontWeight: '400',
            }}
          >
            <Typography sx={{ width: '100%' }}>Privacy Policy</Typography>
            <Typography sx={{ width: '100%', textAlign: 'end' }}>
              Terms of services
            </Typography>
          </Stack> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
